











































































































































































/* Override the ayko styles */
#app a:not(.menu-link):not(.action):not(.link):hover span[data-v-4986dad5] {
  color: #fff;
}
.rc-overlay[data-v-4986dad5] {
  top: 70%;
  left: 50%;
  width: 90vw;
  text-align: left;
  position: absolute;
  transform: translate(-50%, -50%);
}
.rc-heading-one[data-v-4986dad5] {
  font-size: 28px;
}
.rc-heading-two[data-v-4986dad5] {
  font-size: 28px;
}
.rc-height-narrow[data-v-4986dad5],
.rc-height-standard[data-v-4986dad5],
.rc-height-tall[data-v-4986dad5] {
  height: 500px;
}
@media (min-width: 768px) {
.rc-overlay[data-v-4986dad5] {
    top: 70%;
}
.rc-height-narrow[data-v-4986dad5] {
    height: 400px;
}
.rc-height-standard[data-v-4986dad5] {
    height: 500px;
}
.rc-height-tall[data-v-4986dad5] {
    height: 600px;
}
.rc-heading-one[data-v-4986dad5] {
    font-size: 38px;
}
.rc-heading-two[data-v-4986dad5] {
    font-size: 38px;
}
}
@media (min-width: 1280px) {
.rc-overlay[data-v-4986dad5] {
    top: 70%;
}
.rc-height-narrow[data-v-4986dad5] {
    height: 600px;
}
.rc-height-standard[data-v-4986dad5] {
    height: 700px;
}
.rc-height-tall[data-v-4986dad5] {
    height: 800px;
}
.rc-heading-one[data-v-4986dad5] {
    font-size: 70px;
}
.rc-heading-two[data-v-4986dad5] {
    font-size: 70px;
}
}
a:hover span[data-v-4986dad5],
a:focus span[data-v-4986dad5] {
  color: white !important; /* ayko style overide */
}
