
.rc-img-tall[data-v-48c848e9],
.rc-img-standard[data-v-48c848e9] {
  max-height: 400px;
}
@media (min-width: 768px) {
.rc-img-standard[data-v-48c848e9] {
    height: 250px;
}
.rc-img-tall[data-v-48c848e9] {
    height: 500px;
}
}
