
.fade-enter-active[data-v-660f2fd0],
.fade-leave-active[data-v-660f2fd0] {
  transition: opacity 0.5s;
}
.fade-enter[data-v-660f2fd0],
.fade-leave-to[data-v-660f2fd0] {
  opacity: 0;
}
.rc-modal[data-v-660f2fd0] {
  width: 90vw;
  max-height: 90vh;
}
.rc-modal-container[data-v-660f2fd0] {
  z-index: 1000000;
}
@media (min-width: 1280px) {
.rc-modal__landscape[data-v-660f2fd0] {
    width: 1000px;
}
.rc-modal__portrait[data-v-660f2fd0] {
    width: 500px;
}
.rc-modal__square[data-v-660f2fd0] {
    width: 500px;
}
.rc-modal__standard[data-v-660f2fd0] {
    width: 700px;
}
}
