
.rc-modal[data-v-6245c528] {
  width: 90vw;
  max-height: 90vh;
}
@media (min-width: 1280px) {
.rc-modal__landscape[data-v-6245c528] {
    width: 1000px;
}
.rc-modal__portrait[data-v-6245c528] {
    width: 500px;
}
.rc-modal__square[data-v-6245c528] {
    width: 500px;
}
.rc-modal__standard[data-v-6245c528] {
    width: 700px;
}
}
